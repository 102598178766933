<!--begin::Sticky Toolbar-->
<ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
  <!--begin::Item-->
  <li
    class="nav-item mb-2"
    data-toggle="tooltip"
    title="Layout Builder"
    data-placement="left"
    (click)="navigateToBuilder()"
  >
    <a
      class="btn btn-sm btn-icon btn-bg-light btn-icon-primary btn-hover-primary cursor-pointer"
    >
      <i class="flaticon2-gear"></i>
    </a>
  </li>
  <!--end::Item-->

  <!--begin::Item-->
  <li
    class="nav-item mb-2"
    data-toggle="tooltip"
    title="Documentation"
    data-placement="left"
  >
    <a
      class="btn btn-sm btn-icon btn-bg-light btn-icon-warning btn-hover-warning"
      href="https://keenthemes.com/metronic/?page=docs&section=angular-overview"
      target="_blank"
    >
      <i class="flaticon2-telegram-logo"></i>
    </a>
  </li>
  <!--end::Item-->
</ul>
<!--end::Sticky Toolbar-->
