import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ActividadesService {
  constructor(private http: HttpClient) {}

  listActividades(data): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/tareas/actividades/list-actividades.php?column=${data.column}&type_sort=${data.type_sort}`,
      data
    );
  }

  saveActividad(data): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/tareas/actividades/add_or_update.php`,
      data
    );
  }

  finishActivity(data): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/tareas/actividades/finish_activity.php`,
      data
    );
  }

  rejectActivity(data): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/tareas/actividades/reject_activity.php`,
      data
    );
  }

  report_incident(data): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/tareas/actividades/report_incident.php`,
      data
    );
  }

  finishActivitySupervisor(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/tareas/actividades/finish_activity_supervisor.php?id=${id}`
    );
  }

  getActivity(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/tareas/actividades/get.php?id=${id}`
    );
  }

  reopenActivity(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/tareas/actividades/reopen_activity.php?id=${id}`
    );
  }

  pauseActivity(id): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/tareas/actividades/pause_activity.php?`,
      {
        id: id,
      }
    );
  }

  getActivityAll(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/tareas/actividades/get_all.php?id=${id}`
    );
  }

  getBadge(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/tareas/actividades/badge-count.php?id=${id}`
    );
  }

  startActivity(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/tareas/actividades/start_activity.php?id=${id}`
    );
  }

  deleteActivity(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/tareas/actividades/delete.php?id=${id}`
    );
  }

  adjuntarColaborador(data): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/tareas/actividades/adjuntar_colaborador.php`,
      data
    );
  }
}
