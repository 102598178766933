import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LayoutService } from "../../../../_metronic/core";
import { faCog, faStore } from "@fortawesome/free-solid-svg-icons";

import { CambiarSucursalComponent } from "src/app/pages/_layout/components/cambiar-sucursal/cambiar-sucursal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/modules/auth/_services/auth.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Sucursal } from "src/app/modules/catalogos/_models/sucursal.model";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { RecursosHumanosPersonalChecadorComponent } from "src/app/modules/recursos-humanos/recursos-humanos-personal/recursos-humanos-personal-checador/recursos-humanos-personal-checador.component";
import { getAbsoluteValue } from "html2canvas/dist/types/css/types/length-percentage";
import { interval, Subscription } from "rxjs";

@Component({
  selector: "app-aside",
  templateUrl: "./aside.component.html",
  styleUrls: ["./aside.component.scss"],
})
export class AsideComponent implements OnInit {
  disableAsideSelfDisplay: boolean;
  headerLogo: string;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;
  faCog = faCog;
  faStore = faStore;
  badge = 0;

  iconAttrs = {
    width: "150",
    height: "150",
  };
  mySub: Subscription;
  sucursalSeleccionada = 0;
  sucursal = "";
  user: any = {};
  constructor(
    public router: Router,
    private layout: LayoutService,
    private loc: Location,
    private modalService: NgbModal,
    private auth: AuthService,
    private http: HttpClient,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getSucursal();
    this.getUser();
    this.getBadges();

    this.mySub = interval(5000).subscribe((func) => {
      this.getBadges();
    });
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp("aside.self.display") === false;
    this.brandSkin = this.layout.getProp("brand.self.theme");
    this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
    this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown")
      ? "1"
      : "0";
    this.brandClasses = this.layout.getProp("brand");
    this.asideSelfMinimizeToggle = this.layout.getProp(
      "aside.self.minimize.toggle"
    );
    this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
    // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
    // Routing
    this.location = this.loc;
  }

  getBadges() {
    var user = this.auth.getUser();
    const promise = new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.apiUrl}/tareas/actividades/badge_aside.php?id=${user.id}`
        )
        .toPromise()
        .then((res: any) => {
          // console.log("SI ENTRO", res);
          this.badge = res.pendientes + res.supervision;
          this.changeDetectorRefs.detectChanges();
        });
    });

    return null;
  }

  cambiarSucursal() {
    const modalRef = this.modalService.open(CambiarSucursalComponent);

    modalRef.result.then(
      (data) => {
        // on close

        this.getSucursal();
        console.log(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getSucursal() {
    this.sucursalSeleccionada = this.auth.getSucursalSeleccionada();
    if (this.sucursalSeleccionada) {
      this.http
        .get<any>(
          `${environment.apiUrl}/catalogos/sucursales/get.php?id=${this.sucursalSeleccionada}`
        )
        .subscribe((data) => {
          this.sucursal = data.nombre;
          console.log(data);
          this.changeDetectorRefs.detectChanges();
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "No tienes ni una sucursal asignada, por favor ponte en contacto con el administrador.",
        icon: "error",
      }).then((result) => {
        this.router.navigateByUrl("/auth/login");
      });
    }
  }

  getUser() {
    this.user = this.auth.getUser();
    this.changeDetectorRefs.detectChanges();
  }

  findPermiso(permiso) {
    const found = this.user.permisos.find((element) => element == permiso);
    if (typeof found != "undefined") {
      return true;
    } else return false;
  }

  checador() {
    const modalRef = this.modalService.open(
      RecursosHumanosPersonalChecadorComponent,
      { size: "md", backdrop: "static", keyboard: true }
    );
  }
}
