<div class="modal-content">
    <ng-container *ngIf="isLoading">
        <div class="progress progress-modal">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </ng-container>
    <div class="modal-header" *ngIf="!isLoading">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container>
                Alta para reconocimiento facial
            </ng-container>
        </div>
    </div>
    <div class="overlay overlay-block cursor-default modal-body">
        <ng-container *ngIf="!isLoading"> 
            <p>Hola <b style="color: #0080FF;">{{user?.nombre}}</b>! Bienvendio al ERP de Erus</p>
            <p>Hemos notado que es la primera vez que inicias sesion, por lo que necesitamos te tomes una fotografia para activar la funcion de reconocimiento facial de nuestro sistema. </p>
            <p>Diaramente al inicio y final de tu jornada laboral te pediremos marcar entrada y salida por medio de esta funciona. En el menu principal izquierdo dentro del modulo con el nombre “Dashboard General”, podras visualizar en el apartado de Recursos Humanos tu conteo de asistencias.</p>
            <p>Bienvenido a Erus!</p>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-elevate" (click)="tomarFoto()">Tomar foto</button>
    </div>
</div>

 