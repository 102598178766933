<!--begin::Topbar-->


<ng-container *ngIf="extrasNotificationsDisplay">
    <ng-container *ngIf="extrasNotificationsLayout === 'offcanvas'">
        <!--begin::Notifications-->
        <div class="topbar-item">
            <div class="btn btn-icon btn-icon-mobile btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_notifications_toggle">
                <span [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'" cacheSVG="true" class="svg-icon svg-icon-xl svg-icon-primary"></span>
                <span class="pulse-ring"></span>
            </div>
        </div>
        <!--end::Notifications-->
    </ng-container>

    <ng-container *ngIf="extrasNotificationsLayout === 'dropdown'">
        <!--begin::Notifications-->
        <div class="dropdown" ngbDropdown placement="bottom">
            <!--begin::Toggle-->
            <div class="topbar-item" data-offset="10px,0px" ngbDropdownToggle>
                <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
                    <i class="far fa-bell"></i>
                    <span class="pulse-ring"></span>
                    <span class="badge" *ngIf="usuario.notificaciones_nuevas > 0">{{usuario.notificaciones_nuevas}}</span>
                </div>
            </div>
            <!--end::Toggle-->

            <!--begin::Dropdown-->
            <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg">
                <form>
                    <app-notifications-dropdown-inner></app-notifications-dropdown-inner>
                </form>
            </div>
            <!--end::Dropdown-->
        </div>
        <!--end::Notifications-->
    </ng-container>
</ng-container>





<ng-container>
    <ng-container>
        <ng-container>
            <!--begin::User-->
            <div class="topbar-item">
                <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                    <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hola,</span>
                    <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ usuario.nombre }}</span>
                    <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <span class="symbol-label font-weight-bold">{{usuario.nombre | firstLetter}}</span>
                    </span>
                </div>
            </div>
            <!--end::User-->
        </ng-container>
    </ng-container>

    <ng-container *ngIf="extrasUserLayout === 'dropdown'">
        <!--begin::User-->
        <ng-container>
            <div class="dropdown" ngbDropdown placement="bottom-right">
                <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
                    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
                        <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                        <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ usuario.nombre }}</span>
                        <span class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">{{
                usuario.nombre | firstLetter
              }}</span>
                        </span>
                    </div>
                </div>
                <!--end::Toggle-->
                <!--begin::Dropdown-->
                <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
                    <app-user-dropdown-inner></app-user-dropdown-inner>
                </div>
            </div>
            <!--end::Dropdown-->
        </ng-container>
        <!--end::User-->
    </ng-container>
</ng-container>

<!--end::Topbar-->