import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth';
import { ChecadorTomarFotoComponent } from './checador-tomar-foto/checador-tomar-foto.component';

@Component({
  selector: 'app-checador-bienvenida',
  templateUrl: './checador-bienvenida.component.html',
  styleUrls: ['./checador-bienvenida.component.scss']
})
export class ChecadorBienvenidaComponent implements OnInit {
  isLoading = false;
  user: any = {};
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private http: HttpClient,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.getUser();
  }
  getUser() {
    this.user = this.authService.getUser();
  }
  tomarFoto(){
    const modalRef = this.modalService.open(ChecadorTomarFotoComponent, { size: 'ml' }); 
    modalRef.result.then((data) => {
      this.http.get<any>(`${environment.apiUrl}/recursos-humanos/check_photo_status.php?id=`+this.user.id).toPromise().then((res: any) => {
 
        if(res.success){
          this.modal.close();
        }
      }, err => { 
        console.log(err); 
      }); 
      // on close
      console.log(data);
    }, err => {
      console.log(err);
    });
  }
}
