import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LayoutService } from '../../../../../core';

@Component({
  selector: 'app-notifications-dropdown-inner',
  templateUrl: './notifications-dropdown-inner.component.html',
  styleUrls: ['./notifications-dropdown-inner.component.scss'],
})
export class NotificationsDropdownInnerComponent implements OnInit {
  extrasNotificationsDropdownStyle: 'light' | 'dark' = 'dark';
  notificaciones: any[] = [];
  response: any = {};
  nuevas = 0;
  activeTabId:
    | 'topbar_notifications_notifications'
    | 'topbar_notifications_events'
    | 'topbar_notifications_logs' = 'topbar_notifications_logs';
  constructor(private http: HttpClient, private layout: LayoutService, private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.listNotifications();
    this.extrasNotificationsDropdownStyle = this.layout.getProp(
      'extras.notifications.dropdown.style'
    );
  }

  listNotifications() {
    this.authService.getNotifications().subscribe(data => {
      this.response = data;
      this.notificaciones = this.response.listado;
      this.nuevas = this.response.nuevas;
      console.log(data);
    });
  }

  ver() {
    this.router.navigate(['/herramientas/notificaciones'])
  }

  selectNotification(n) {

  }

  setLeidos() {

    Swal.fire({
      title: '¿Estas seguro que deseas marcar todas tus notificaciones como leídas?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Sí'
    }).then((result) => {
      if (result.isConfirmed) {

        this.http.post(`${environment.apiUrl}/misc/set_leidos.php`, {todos: 1, usuario_id: this.authService.getUser().id}).subscribe(data => {
          console.log(data);
          this.listNotifications();
          Swal.fire("¡Éxito!", "Se han marcado las notificaciones como leídas.", "success");
  
        }, err=> {
          Swal.fire("Error", err.error.text, "error");
        });
    
   
      }
    })


  }

  setActiveTabId(tabId) {
    this.activeTabId = tabId;
  }

  getActiveCSSClasses(tabId) {
    if (tabId !== this.activeTabId) {
      return '';
    }
    return 'active show';
  }
}
