<div class="modal-header">
    <div class="modal-title h4">Configuración</div>
</div>
<div class="modal-body">

    <ng-container>
        <mat-form-field appearance="fill">
            <mat-label>Sucursal</mat-label>
            <mat-select [(ngModel)]="sucursalSeleccionada" placeholder="Sucursal" #singleSelect style="width: 100%;">
                <mat-option>
                    <ngx-mat-select-search placeholderLabel="Buscar sucursal..." noEntriesFoundLabel="No se encontraron sucursales" [formControl]="sucursalFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let c of filteredSucursal | async" [value]="c.id">
                    {{c.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field appearance="fill">
            <mat-label>Usuario usado para iniciar sesión</mat-label>
            <input (ngModelChange)="setChanged()" matInput placeholder="Correo" name="correo" autocomplete="off" [(ngModel)]="user.correo" />
            <mat-hint *ngIf="!user.correo">Ingresa tu correo</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Contraseña</mat-label>
            <input (ngModelChange)="setChanged()" matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'" name="password" autocomplete="off" [(ngModel)]="user.password" />
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint *ngIf="!user.password">Ingresa tu contraseña</mat-hint>
        </mat-form-field>
    </ng-container>


</div>

<div class="modal-footer">
    <div>
        <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
        Cancelar
      </button>&nbsp;
        <button type="button" class="btn btn-primary btn-elevate" (click)="cambiar()">
        Guardar
      </button>
    </div>
</div>