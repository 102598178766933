import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { ActividadesService } from "../../services/actividades.service";

@Component({
  selector: "app-modal-terminar",
  templateUrl: "./modal-terminar.component.html",
  styleUrls: ["./modal-terminar.component.scss"],
})
export class ModalTerminarComponent implements OnInit {
  @Input() id: Number;

  resolucion = "";
  data: any = {};
  constructor(
    public modal: NgbActiveModal,
    private _activity: ActividadesService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getActivity();
  }

  getActivity() {
    this.spinner.show("finishActivity");
    this._activity.getActivityAll(this.id).subscribe(
      (e) => {
        this.spinner.hide("finishActivity");
        this.data = e;
      },
      (err) => {
        this.spinner.hide("finishActivity");
        console.error(err);
      }
    );
  }

  finishActivity() {
    if (this.resolucion.trim()) {
      this.spinner.show("finishActivity");
      this._activity
        .finishActivity({ id: this.id, resolucion: this.resolucion.trim() })
        .subscribe(
          (e) => {
            this.spinner.hide("finishActivity");
            this.modal.close();
          },
          (err) => {
            this.spinner.hide("finishActivity");
            console.error(err);
          }
        );
    } else {
      Swal.fire("Todos los campos son requeridos","", "warning");
    }
  }
}
