import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { io } from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SocketioService {
  socket;
  constructor(private toastr: ToastrService, private router: Router) {}

  setupSocketConnection() {
    this.socket = io(environment.SOCKET_ENDPOINT, {
      path:"/socket.io",
      auth: {
        token: "dedb2b7e-c7c0-4ed9-b7dd-197908574aae",
      },
    });
    this.socket.on("connect_error", (err: any) => {
      console.log(err);
      console.log(`connect_error due to ${err.message}`);
    });
  }

  sendMessage(channel, message) {
    var json = {
      channel: channel,
      message: message,
    };

    var stringify = JSON.stringify(json);
    this.socket.emit("message", stringify);
  }

  subscribe(channel) {
    this.disconnect();
    this.setupSocketConnection();
    this.socket.on(channel, (data: string) => {
      this.toastr
        .success(
          "(Haz click aquí para ver más detalles)",
          "Se a generado una nueva cita en Canjea Tu Vale",
          {
            timeOut: 90000,
            extendedTimeOut: 9000,
            progressBar: true,
          }
        )
        .onTap.pipe()
        .subscribe(() => this.toasterClickedHandler(data));
    });
  }
  toasterClickedHandler(id) {
    // this.router.navigateByUrl(
    //   `/ventas/ordenes-de-venta;orden_venta_id=${id}`
    // );

    this.router
      .navigateByUrl("/ventas", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([`/ventas/ordenes-de-venta`], {
          queryParams: { orden_venta_id: id },
        });
      });
  }
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
