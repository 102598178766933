import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg";
import { PagesRoutingModule } from "./pages-routing.module";
import {
  NgbDropdownModule,
  NgbModule,
  NgbProgressbarModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslationModule } from "../modules/i18n/translation.module";
import { LayoutComponent } from "./_layout/layout.component";
import { ScriptsInitComponent } from "./_layout/init/scipts-init/scripts-init.component";
import { HeaderMobileComponent } from "./_layout/components/header-mobile/header-mobile.component";
import { AsideComponent } from "./_layout/components/aside/aside.component";
import { FooterComponent } from "./_layout/components/footer/footer.component";
import { HeaderComponent } from "./_layout/components/header/header.component";
import { HeaderMenuComponent } from "./_layout/components/header/header-menu/header-menu.component";
import { TopbarComponent } from "./_layout/components/topbar/topbar.component";
import { ExtrasModule } from "../_metronic/partials/layout/extras/extras.module";
import { LanguageSelectorComponent } from "./_layout/components/topbar/language-selector/language-selector.component";
import { CoreModule } from "../_metronic/core";
import { SubheaderModule } from "../_metronic/partials/layout/subheader/subheader.module";
import { AsideDynamicComponent } from "./_layout/components/aside-dynamic/aside-dynamic.component";
import { HeaderMenuDynamicComponent } from "./_layout/components/header/header-menu-dynamic/header-menu-dynamic.component";
import { SortIconComponent } from "../modules/_components/sort-icon/sort-icon.component";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import { faCog, faStore } from "@fortawesome/free-solid-svg-icons";
import { CambiarSucursalComponent } from "./_layout/components/cambiar-sucursal/cambiar-sucursal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatIconModule } from "@angular/material/icon";
import { ChangePasswordComponent } from "./_layout/components/change-password/change-password.component";
import { SocketioService } from "./_layout/components/header/socketio.service";
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";

//UGO4APUXGTQVAH7P
@NgModule({
  declarations: [
    LayoutComponent,
    ScriptsInitComponent,
    HeaderMobileComponent,
    AsideComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    TopbarComponent,
    LanguageSelectorComponent,
    AsideDynamicComponent,
    HeaderMenuDynamicComponent,
    SortIconComponent,
    CambiarSucursalComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ToastrModule.forRoot(),
    TranslationModule,
    InlineSVGModule,
    ExtrasModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    CoreModule,
    SubheaderModule,
    InlineSVGModule,
    FontAwesomeModule,
    MatIconModule,
    NgbModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    SortIconComponent,
    CambiarSucursalComponent,
    ChangePasswordComponent,
  ],
  providers: [SocketioService],
})
export class LayoutModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faCog, faStore);
  }
}
