<div class="modal-header">
    <div class="modal-title h4">Primer inicio de sesión</div>
</div>
<div class="modal-body">

    <p>Es tu primera vez iniciando sesión al nuevo ERP de Erus, por favor ingresa tu <span style="font-weight: bold;">nueva contraseña.</span></p>
    <ng-container>


        <p style="margin-top: 16px;"><span style="font-weight: bold;">Usuario usado para iniciar sesión:</span> {{user.correo}}</p>


        <p style="margin-top: 16px; font-weight: bold;">Contraseña: </p>

        <mat-form-field appearance="fill">
            <input matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'" name="password" autocomplete="off" [(ngModel)]="user.password" />
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-hint *ngIf="!user.password">Ingresa tu contraseña</mat-hint>
        </mat-form-field>
    </ng-container>


</div>

<div class="modal-footer">
    <div>
        <!-- <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
        Cambiar en otro
      </button>&nbsp; -->
        <button type="button" class="btn btn-primary btn-elevate" (click)="cambiar()">
        Guardar
      </button>
    </div>
</div>