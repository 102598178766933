import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { LayoutService, LayoutInitService } from "../../_metronic/core";
import KTLayoutContent from "../../../assets/js/layout/base/content";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { animate, style, transition, trigger } from "@angular/animations";
import { AuthService } from "src/app/modules/auth";
import { interval, Subscription } from "rxjs";
import { NgxSpinner } from "ngx-spinner/lib/ngx-spinner.enum";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalTerminarComponent } from "src/app/modules/asignador-de-tareas/mis-actividades/modal-terminar/modal-terminar.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(100, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  // Public variables
  haveHelp = false;

  haveActivity = false;
  activity: any;

  helpUrl = "";
  selfLayout = "default";
  asideSelfDisplay: true;
  asideMenuStatic: true;
  contentClasses = "";
  contentContainerClasses = "";
  subheaderDisplay = true;
  contentExtended: false;
  asideCSSClasses: string;
  asideHTMLAttributes: any = {};
  headerMobileClasses = "";
  headerMobileAttributes = {};
  footerDisplay: boolean;
  footerCSSClasses: string;
  headerCSSClasses: string;
  headerHTMLAttributes: any = {};
  // offcanvases
  extrasSearchOffcanvasDisplay = false;
  extrasNotificationsOffcanvasDisplay = false;
  extrasQuickActionsOffcanvasDisplay = false;
  extrasCartOffcanvasDisplay = false;
  extrasUserOffcanvasDisplay = false;
  extrasQuickPanelDisplay = false;
  extrasScrollTopDisplay = false;
  @ViewChild("ktAside", { static: true }) ktAside: ElementRef;
  @ViewChild("ktHeaderMobile", { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild("ktHeader", { static: true }) ktHeader: ElementRef;
  mySub: Subscription;

  constructor(
    private initService: LayoutInitService,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private layout: LayoutService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private auth: AuthService,
    private changeDetectorRefs: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
    this.getHelp(this.router.url);
    this.initService.init();
  }

  ngOnInit(): void {
    this.getActivities();

    this.mySub = interval(3000).subscribe((func) => {
      this.getActivities();
    });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.getHelp(this.router.url);
      }
    });
    // build view by layout config settings
    this.selfLayout = this.layout.getProp("self.layout");
    this.asideSelfDisplay = this.layout.getProp("aside.self.display");
    this.asideMenuStatic = this.layout.getProp("aside.menu.static");
    this.subheaderDisplay = this.layout.getProp("subheader.display");
    this.contentClasses = this.layout.getStringCSSClasses("content");
    this.contentContainerClasses =
      this.layout.getStringCSSClasses("content_container");
    this.contentExtended = this.layout.getProp("content.extended");
    this.asideHTMLAttributes = this.layout.getHTMLAttributes("aside");
    this.asideCSSClasses = this.layout.getStringCSSClasses("aside");
    this.headerMobileClasses = this.layout.getStringCSSClasses("header_mobile");
    this.headerMobileAttributes =
      this.layout.getHTMLAttributes("header_mobile");
    this.footerDisplay = this.layout.getProp("footer.display");
    this.footerCSSClasses = this.layout.getStringCSSClasses("footer");
    this.headerCSSClasses = this.layout.getStringCSSClasses("header");
    this.headerHTMLAttributes = this.layout.getHTMLAttributes("header");
    // offcanvases
    if (this.layout.getProp("extras.search.display")) {
      this.extrasSearchOffcanvasDisplay =
        this.layout.getProp("extras.search.layout") === "offcanvas";
    }

    if (this.layout.getProp("extras.notifications.display")) {
      this.extrasNotificationsOffcanvasDisplay =
        this.layout.getProp("extras.notifications.layout") === "offcanvas";
    }

    if (this.layout.getProp("extras.quickActions.display")) {
      this.extrasQuickActionsOffcanvasDisplay =
        this.layout.getProp("extras.quickActions.layout") === "offcanvas";
    }

    if (this.layout.getProp("extras.cart.display")) {
      this.extrasCartOffcanvasDisplay =
        this.layout.getProp("extras.cart.layout") === "offcanvas";
    }

    if (this.layout.getProp("extras.user.display")) {
      this.extrasUserOffcanvasDisplay =
        this.layout.getProp("extras.user.layout") === "offcanvas";
    }

    this.extrasQuickPanelDisplay = this.layout.getProp(
      "extras.quickPanel.display"
    );

    this.extrasScrollTopDisplay = this.layout.getProp(
      "extras.scrolltop.display"
    );
  }

  ngAfterViewInit(): void {
    if (this.ktAside) {
      for (const key in this.asideHTMLAttributes) {
        if (this.asideHTMLAttributes.hasOwnProperty(key)) {
          this.ktAside.nativeElement.attributes[key] =
            this.asideHTMLAttributes[key];
        }
      }
    }

    if (this.ktHeaderMobile) {
      for (const key in this.headerMobileAttributes) {
        if (this.headerMobileAttributes.hasOwnProperty(key)) {
          this.ktHeaderMobile.nativeElement.attributes[key] =
            this.headerMobileAttributes[key];
        }
      }
    }

    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] =
            this.headerHTMLAttributes[key];
        }
      }
    }
    // Init Content
    KTLayoutContent.init("kt_content");
  }

  getHelp(url) {
    this.haveHelp = false;
    this.helpUrl = "";
    const promise = new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.apiUrl}/herramientas/capacitate-relaciones/get_by_name.php?url_erp=${url}`
        )
        .toPromise()
        .then(
          (res: any) => {
            this.spinner.hide("getIfActivity");
            if (res.id) {
              this.haveHelp = true;
              this.helpUrl = res.url_capacitate;
              // console.log("help", res);
            }
          },
          (err) => {
            console.error(err);
          }
        );
    });
  }

  goToCapacitate() {
    // this.router.navigateByUrl(`${this.helpUrl}`);
    window.open(this.helpUrl, "_blank");
  }

  secondsToString(seconds) {
    var hour: any;
    var minute: any;
    var second: any;
    hour = Math.floor(seconds / 3600);
    hour = hour < 10 ? "0" + hour : hour;
    minute = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    second = seconds % 60;
    second = second < 10 ? "0" + second : second;
    return hour + " horas " + minute + " minutos ";
  }

  getActivities() {
    var user = this.auth.getUser();
    const promise = new Promise((resolve, reject) => {
      this.http
        .get<any>(
          `${environment.apiUrl}/tareas/actividades/get_activity_on_attention.php?id=${user.id}`
        )
        .toPromise()
        .then((res: any) => {
          if (res.actividad) {
            this.activity = res.actividad;
            this.haveActivity = true;
          } else {
            this.haveActivity = false;
          }

          this.changeDetectorRefs.detectChanges();
        });
    });
    return null;
  }

  pauseActivity() {
    this.spinner.show("getIfActivity");
    var user = this.auth.getUser();
    const promise = new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${environment.apiUrl}/tareas/actividades/pause_activity.php?`,
          {
            id: this.activity.id,
          }
        )
        .toPromise()
        .then((res: any) => {
          console.log(res);
          this.changeDetectorRefs.detectChanges();
        });
    });
  }

  finishActivity() {
    const modalRef = this.modalService.open(ModalTerminarComponent, {
      size: "xl",
      backdrop: "static",
    });
    modalRef.componentInstance.id = this.activity.id;

    modalRef.result.then(
      (data) => {
        this.modal.dismiss();

        // on close
        //console.log(data);
      },
      (err) => {
        this.modal.dismiss();

        //console.log(err);
      }
    );
  }
}
