import { AuthModel } from './auth.model';

export class UserModel extends AuthModel {
  id: number;
  nombre: string;
  correo: string;
  puesto_id: number;
  permisos: any[];
  sucursales: any[];

  setUser(user: any) {
    this.id = user.id;
    this.nombre = user.nombre || '';
    this.correo = user.correo || '';
    this.puesto_id = user.puesto_id;
    this.permisos = user.permisos || [];
  }
}
