<!-- begin::Header Nav -->
<ul class="menu-nav" [ngClass]="ulCSSClasses">
    <!-- begin::1 Level -->
  <!--   <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
        <a class="menu-link" routerLink="/dashboard" routerLinkActive="active">
            <span class="menu-text">Dashboard</span>
            <ng-container *ngIf="rootArrowEnabled">
                <i class="menu-arrow"></i>
            </ng-container>
        </a>
    </li>

    <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
        <a class="menu-link" (click)="cron()" routerLinkActive="active">
            <span class="menu-text">SOSKE</span>
            <ng-container *ngIf="rootArrowEnabled">
                <i class="menu-arrow"></i>
            </ng-container>
        </a>
    </li>
    <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
        <a class="menu-link" (click)="cron2()" routerLinkActive="active">
            <span class="menu-text">CRON</span>
            <ng-container *ngIf="rootArrowEnabled">
                <i class="menu-arrow"></i>
            </ng-container>
        </a>
    </li> -->

</ul>
<!-- end::Header Nav -->