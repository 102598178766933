import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Sucursal } from 'src/app/modules/catalogos/_models/sucursal.model';
import { AuthService } from 'src/app/modules/auth/_services/auth.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-cambiar-sucursal',
  templateUrl: './cambiar-sucursal.component.html',
  styleUrls: ['./cambiar-sucursal.component.scss']
})
export class CambiarSucursalComponent implements OnInit {

  protected _onDestroy = new Subject<void>();

  public filteredSucursal: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public sucursalFilterCtrl: FormControl = new FormControl();

  sucursales: Sucursal[] = [];
  sucursalSeleccionada = 0;
  hide = true;

  user: any = {};
  changed = false;

  constructor(private http: HttpClient, public modal: NgbActiveModal, private auth: AuthService) { }

  ngOnInit(): void {
    this.listSucursales();

    this.sucursalSeleccionada = this.auth.getSucursalSeleccionada();

    this.getUser();

    this.sucursalFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterSucursales();
    });
  }

  getUser() {
    this.user = this.auth.getUser();
    console.log(this.user);
  }

  listSucursales() {
    this.http.post<any>(`${environment.apiUrl}/catalogos/sucursales/list_filter.php`, {sucursales: this.auth.getUser().sucursales}).subscribe(data => {
      this.sucursales = data;

    this.filteredSucursal.next(this.sucursales.slice());

    // listen for search field value changes
    this.sucursalFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSucursales();
      });
    });
  }
  protected filterSucursales() {
    if (!this.sucursales) {
      return;
    }
    // get the search keyword
    let search = this.sucursalFilterCtrl.value;
    if (!search) {
      this.filteredSucursal.next(this.sucursales.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSucursal.next(
      this.sucursales.filter(servicio => servicio.nombre.toLowerCase().indexOf(search) > -1)
    );
  }

  setChanged() {
    this.changed = true;
  }


  cambiar() {
    if(this.changed) {
      console.log(this.sucursalSeleccionada);
      this.http.post<any>(`${environment.apiUrl}/auth/set_info.php`, this.user).subscribe(data => {
        console.log(data);
        if(data.success) {


          Swal.fire({
            title: '¡Éxito!',
            text: "Se han modificado las credenciales del usuario.",
            icon: 'success'
          }).then((result) => {

            this.auth.setSucursal(this.sucursalSeleccionada);
            console.log(this.auth.getSucursalSeleccionada());
            //window.location.reload();
          })
        } else {
          Swal.fire("Error", data.message, "error");
        }

      }, err => {
        Swal.fire("Error", err.error.text, "error");
        console.log(err);
      });
    } else {
      this.auth.setSucursal(this.sucursalSeleccionada);
      window.location.reload();
    }



  }
}
