import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './modules/auth';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from './pages/_layout/components/change-password/change-password.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment'; 
import { ContactCenterConfirmarCitaComponent } from './modules/contact-center/contact-center-confirmar-cita/contact-center-confirmar-cita.component';
import { ChecadorBienvenidaComponent } from './modules/recursos-humanos/checador-bienvenida/checador-bienvenida.component';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private routeSub:any;
  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private http: HttpClient,
    public modal: NgbActiveModal
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.getUser();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        var other = this;
        setTimeout(() => {
          document.body.classList.add('page-loaded');
          other.changePassword();
        }, 100);
      }
    });
    this.unsubscribe.push(routerSubscription);
    this.authService.getSucursalSeleccionada();
    this.authService.getAlmacenSeleccionado();
    this.checkAuditorias();
    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.checkAuditorias();
        this.confirmarCitas();
      }
    });
    this.RevisarFotoChecador();
     
    
  } 

  getUser() {
    const session = localStorage.getItem('session');
    if(!session) {
      this.router.navigateByUrl("/auth/login");
    } else {
      this.authService.updateSession();
    }
  }

  changePassword() {
    var user = this.authService.getUser();
    if(user.first_login == '1') {
        const modalRef = this.modalService.open(ChangePasswordComponent, { size: 'md', backdrop: 'static', keyboard: false});
    
        modalRef.result.then((data) => {
          // on close
          console.log(data);
        }, err => {
          console.log(err);
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  checkAuditorias(){  
    var user = this.authService.getUser();
    
    this.http.get<any>(`${environment.apiUrl}/almacen/reporte-inventario/check_auditoria.php?sucursal=`+this.authService.getSucursalSeleccionada()).toPromise().then((res: any) => { 
      if(res.success) {
        let startdate = res.result[0].created_at;
        let datefirst =  moment(startdate, "YYYY-MM-DD h:mm:ss");
        var new_date = moment();
        let hours = moment().diff(moment(datefirst), 'hours'); 
        if(hours >= 24){
          this.router.navigateByUrl("almacen/reporte-inventarios/ver/"+res.result[0].id);
        } else {
          if(user.departamento_id == 1 || user.departamento_id == 3){
            Swal.fire({ 
              title: "AUDITORIA DE INVENTARIO",
              text:'Haz recibido una auditoria de inventario, tienes fecha "'+new_date.format( "YYYY-MM-DD h:mm:ss")+'" para realizarla de lo contrario el sistema se bloqueara.',
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'Realizar',
              cancelButtonText: 'Cerrar',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigateByUrl("almacen/reporte-inventarios/ver/"+res.result[0].id);
              } 
            })
          }
        }  
      }
    }, err => { 
      console.log(err); 
    }); 
  }
  confirmarCitas(){
    var user = this.authService.getUser();
    if(user.departamento_id == 1){
      this.http.get<any>(`${environment.apiUrl}/contact_center/list_citas.php?vendedor_id=`+user.id).toPromise().then((res: any) => {
     
        if(res.length  > 0) {
          const modalRef = this.modalService.open(ContactCenterConfirmarCitaComponent, { size: 'xl' });
          modalRef.componentInstance.list = res;
          modalRef.result.then((data) => {
            // on close
            console.log(data);
          }, err => {
            console.log(err);
          });
        }
      }, err => { 
        console.log(err); 
      }); 
       
    } 
  }
  RevisarFotoChecador(){
    var user = this.authService.getUser();
    //if(user.departamento_id == 1){
      this.http.get<any>(`${environment.apiUrl}/recursos-humanos/check_photo.php?id=`+user.id).toPromise().then((res: any) => {
 
        if(res == '1') {
          const modalRef = this.modalService.open(ChecadorBienvenidaComponent, { size: 'ml' }); 
          modalRef.result.then((data) => {
            
            this.modal.close();
          }, err => {
            console.log(err);
          });
        }
      }, err => { 
        console.log(err); 
      }); 
       
    //} 

     
  }
}
