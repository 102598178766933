import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LayoutService } from '../../../../../_metronic/core';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

function getCurrentURL(location) {
  return location.split(/[?#]/)[0];
}

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  ulCSSClasses: string;
  rootArrowEnabled: boolean;
  location: Location;
  headerMenuDesktopToggle: string;

  constructor(private layout: LayoutService, private loc: Location, private http: HttpClient) {
    this.location = this.loc;
  }

  ngOnInit(): void {
    this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
    this.rootArrowEnabled = this.layout.getProp('header.menu.self.rootArrow');
    this.headerMenuDesktopToggle = this.layout.getProp(
      'header.menu.desktop.toggle'
    );
  }

  getMenuItemActive(url) {
    return this.checkIsActive(url) ? 'menu-item-active' : '';
  }

  checkIsActive(url) {
    const location = this.location.path();
    const current = getCurrentURL(location);
    if (!current || !url) {
      return false;
    }

    if (current === url) {
      return true;
    }

    if (current.indexOf(url) > -1) {
      return true;
    }

    return false;
  }

  clean() {
    Swal.fire({
      title: '¡Atención!',
      text: "¿Estas seguro que deseas limpiar el sistema?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed) {
        /*
        this.http.get<any>(`${environment.apiUrl}/misc/clean.php`).subscribe(data => {
          console.log(data);
          Swal.fire("¡Éxito!", "Se ha limpiado el sistema.", "success");
        });
        */
      }

    }, err => {
      Swal.fire("Error", err.error.text, "error");
    });
  }

  asignarCosto() {
    this.http.get<any>(`${environment.apiUrl}/ventas/asignar_costo_productos.php`).subscribe(data => {
      console.log(data);
      Swal.fire("¡Éxito!", "meep", "question");
    });
  }

  cron() {
    Swal.fire({
      title: '¡Atención!',
      text: "¿Ejecutar soskeboske?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed) {
        //this.asignarCosto();
        
        this.http.get<any>(`${environment.apiUrl}/misc/soskeboske4.php`).subscribe(data => {
          console.log(data);
          Swal.fire("¡Éxito!", "meep", "question");
        });
        
      }

    }, err => {
      Swal.fire("Error", err.error.text, "error");
    });
  }
  cron2() {
    Swal.fire({
      title: '¡Atención!',
      text: "¿Ejecutar cambio de totales?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed) {
        this.asignarCosto();
        

      }

    }, err => {
      Swal.fire("Error", err.error.text, "error");
    });
  }
}
