import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";
import { map, catchError, switchMap, finalize } from "rxjs/operators";
import { UserModel } from "../_models/user.model";
import { AuthModel } from "../_models/auth.model";

import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { AuthHTTPService } from "./auth-http/auth-http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private http: HttpClient
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods

  /*
        this.http.post(`${environment.apiUrl}/auth/login.php`, this.usuario).subscribe(data => {
      console.log(data);
      var res: any = data;

      localStorage.setItem('session', JSON.stringify(data));

      if(res.success == "1") {
        localStorage.setItem('session', JSON.stringify(data));
        this.router.navigateByUrl("vista-rapida");
      } else {
        this.hasError = true;
      }
    });
  */

  login(correo: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(correo, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error("err", err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getNotifications() {
    const currentUser = localStorage.getItem("session");
    var user_id = JSON.parse(currentUser).id;
    return this.http.post(
      `${environment.apiUrl}/misc/list_notificaciones.php`,
      { id: user_id }
    );
  }

  logout() {
    // var dash = localStorage.getItem("dash");
    localStorage.removeItem("generacion");
    localStorage.removeItem("session");
    localStorage.removeItem("almacenSeleccionado");
    localStorage.removeItem("sucursalSeleccionada");
    // if (dash) {
    //   localStorage.setItem("dash", dash);
    // }
    this.router.navigate(["/auth/login"], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.accessToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateSession() {
    const currentUser = localStorage.getItem("session");
    var user = JSON.parse(currentUser);
    if (currentUser) {
      this.http
        .post(`${environment.apiUrl}/auth/login.php`, {
          correo: user.correo,
          password: user.password,
        })
        .subscribe(
          (data) => {
            var res: any = data;

            localStorage.setItem("session", JSON.stringify(data));

            if (res.success) {
              localStorage.setItem("session", JSON.stringify(data));

              //almacenSeleccionado
              var sucursal = this.getSucursalSeleccionada();
              this.http
                .get<any>(
                  `${environment.apiUrl}/catalogos/almacenes/list_filter.php?sucursal_id=${sucursal}`
                )
                .subscribe((data) => {
                  var almacenes = data;
                  localStorage.setItem("almacenSeleccionado", almacenes[0].id);
                });
            } else this.router.navigateByUrl("auth/login");
          },
          (err) => {
            this.router.navigateByUrl("auth/login");
            console.log(err);
          }
        );
    } else this.router.navigateByUrl("auth/login");
  }

  getSucursalSeleccionada() {
    var sucursal = localStorage.getItem("sucursalSeleccionada");
    console.log(sucursal);
    if (sucursal) {
      return parseInt(sucursal);
    } else {
      const currentUser = localStorage.getItem("session");
      if (currentUser) {
        var user = JSON.parse(currentUser);
        localStorage.setItem("sucursalSeleccionada", user.sucursales[0]);
        return parseInt(user.sucursales[0]);
      } else return;
    }
  }
  getAlmacenSeleccionado() {
    var almacen = localStorage.getItem("almacenSeleccionado");
    if (almacen) {
      return parseInt(almacen);
    } else {
      const currentUser = localStorage.getItem("session");
      if (currentUser) {
        //var user = JSON.parse(currentUser);
        var sucursal = this.getSucursalSeleccionada();
        this.http
          .get<any>(
            `${environment.apiUrl}/catalogos/almacenes/list_filter.php?sucursal_id=${sucursal}`
          )
          .subscribe((data) => {
            var almacenes = data;
            localStorage.setItem("almacenSeleccionado", almacenes[0].id);
            return parseInt(almacenes[0].id);
          });
      } else return;
    }
  }
  isAdmin() {
    const currentUser = localStorage.getItem("session");
    if (currentUser) {
      var user = JSON.parse(currentUser);
      if (user.superadmin == 1) {
        return true;
      } else {
        return false;
      }
    } else false;
  }

  getUser() {
    const currentUser = localStorage.getItem("session");
    if (currentUser) {
      var user = JSON.parse(currentUser);
      return user;
    } else return;
  }

  setSucursal(sucursal) {
    console.log(sucursal);
    localStorage.setItem("sucursalSeleccionada", sucursal);
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
