<ng-container *ngIf="subheaderVersion$ | async as _version">
  <ng-container *ngIf="_version === 'v1'">
    <app-subheader1></app-subheader1>
  </ng-container>

  <ng-container *ngIf="_version === 'v2'">
    <app-subheader2></app-subheader2>
  </ng-container>

  <ng-container *ngIf="_version === 'v3'">
    <app-subheader3></app-subheader3>
  </ng-container>

  <ng-container *ngIf="_version === 'v4'">
    <app-subheader4></app-subheader4>
  </ng-container>

  <ng-container *ngIf="_version === 'v5'">
    <app-subheader5></app-subheader5>
  </ng-container>

  <ng-container *ngIf="_version === 'v6'">
    <app-subheader6></app-subheader6>
  </ng-container>

  <ng-container *ngIf="_version === 'v7'">
    <app-subheader7></app-subheader7>
  </ng-container>
</ng-container>