import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth';
import { SortState } from '../../_components/sort-icon/sort.model';

@Component({
  selector: 'app-contact-center-confirmar-cita',
  templateUrl: './contact-center-confirmar-cita.component.html',
  styleUrls: ['./contact-center-confirmar-cita.component.scss']
})
export class ContactCenterConfirmarCitaComponent implements OnInit {
  @Input() list: Object; 
  sorting: SortState = {column: "asistio", reverse: true};
  isLoading: boolean;
  busqueda = "";
  page = 1;
  pageSize = 10;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private changeDetectorRefs: ChangeDetectorRef,
    private modalService: NgbModal,
    private authService: AuthService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void { 
  }
  accion(id, type){  
    var user = this.authService.getUser();
    this.http.post<any>(`${environment.apiUrl}/contact_center/estado_cita.php`, { id: id, type: type}).subscribe(data => {  
      this.http.get<any>(`${environment.apiUrl}/contact_center/list_citas.php?vendedor_id=`+user.id).toPromise().then((res: any) => {
     
        if(res.length  > 0) {
          this.list = res;
        } else {
          this.modal.close();
          this.list = [];
        }
      }, err => { 
        console.log(err); 
      }); 
    });
  }
  
}
