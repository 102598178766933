import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/modules/auth';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LocationService } from '../../_services/location.service';

@Component({
  selector: 'app-checador-tomar-foto',
  templateUrl: './checador-tomar-foto.component.html',
  styleUrls: ['./checador-tomar-foto.component.scss']
})
export class ChecadorTomarFotoComponent implements OnInit {
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  public allowCameraSwitch = true;
  latitude: any;
  longitude: any;
  constructor(
    private authService: AuthService,
    public locationService: LocationService,
    private changeDetectorRefs: ChangeDetectorRef,
    private http: HttpClient,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  getLocation() {
    this.locationService.getPosition().then(pos => { 
      this.latitude = pos.lat;
      this.longitude = pos.lng; 
      this.changeDetectorRefs.detectChanges();
    });
  }
  triggerSnapshot(): void {
    this.trigger.next();
  }
  async handleImage(webcamImage: WebcamImage) { 
    let formData = new FormData();
    var blob = this.dataURItoBlob(webcamImage.imageAsDataUrl);
    formData.append("img", blob);
    formData.append("id", this.authService.getUser().id);
    formData.append("lng",  this.longitude);
    formData.append("lat", this.latitude);  
    formData.append("entrada", this.authService.getUser().inicio_jornada.split("-")[1]);
    formData.append("salida", this.authService.getUser().fin_jornada.split("-")[1]);  
    this.http.post(`${environment.urlRH}/upload/perfil`,formData, {responseType: 'text'}).subscribe(data => {
     
      if(data){
        //Swal.fire("Erus", data, "warning");
        this.modal.close(); 
      }
    }); 
 
     
    this.webcamImage = webcamImage;
   }
    
   public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
   }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


  }
}
