import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(correo: string, password: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login.php`, { correo, password });
  }

  /*
      this.http.post(`${environment.apiUrl}/auth/login.php`, this.usuario).subscribe(data => {
      console.log(data);
      var res: any = data;

      localStorage.setItem('session', JSON.stringify(data));

      if(res.success == "1") {
        localStorage.setItem('session', JSON.stringify(data));
        this.router.navigateByUrl("vista-rapida");
      } else {
        this.hasError = true;
      }
    });
  */
  
    getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}`, {
      headers: httpHeaders,
    });
  }
  
}
