import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth';
import { Sucursal } from 'src/app/modules/catalogos/_models/sucursal.model';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  protected _onDestroy = new Subject<void>();

  public filteredSucursal: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public sucursalFilterCtrl: FormControl = new FormControl();

  sucursales: Sucursal[] = [];
  sucursalSeleccionada = 0;
  hide = true;

  user: any = {};
  changed = false;

  constructor(private http: HttpClient, public modal: NgbActiveModal, private auth: AuthService) { }

  ngOnInit(): void {


    this.sucursalSeleccionada = this.auth.getSucursalSeleccionada();

    this.getUser();


  }

  getUser() {
    this.user = this.auth.getUser();
    console.log(this.user);
  }


  cambiar() {

      this.http.post<any>(`${environment.apiUrl}/auth/set_info.php`, this.user).subscribe(data => {
        console.log(data);
        if(data.success) {


          Swal.fire({
            title: '¡Éxito!',
            text: "Se ha guardado la contraseña.",
            icon: 'success'
          }).then((result) => {
            
              window.location.reload();
            
          })
        } else {
          Swal.fire("Error", data.message, "error");
        }

      }, err => {
        Swal.fire("Error", err.error.text, "error");
        console.log(err);
      });
    }
}
