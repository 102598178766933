import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./_layout/layout.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "builder",
        loadChildren: () =>
          import("./builder/builder.module").then((m) => m.BuilderModule),
      },
      {
        path: "catalogos",
        loadChildren: () =>
          import("../modules/catalogos/catalogos.module").then(
            (m) => m.CatalogosModule
          ),
      },
      {
        path: "almacen",
        loadChildren: () =>
          import("../modules/almacen/almacen.module").then(
            (m) => m.AlmacenModule
          ),
      },
      {
        path: "ventas",
        loadChildren: () =>
          import("../modules/ventas/ventas.module").then((m) => m.VentasModule),
      },
      {
        path: "crm",
        loadChildren: () =>
          import("../modules/crm/crm.module").then((m) => m.CrmModule),
      }, 
      {
        path: "dashboard",
        loadChildren: () =>
          import("../modules/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "nuevo-dashboard",
        loadChildren: () =>
          import("../modules/nuevo-dashboard/nuevo-dashboard.module").then(
            (m) => m.NuevoDashboardModule
          ),
      },
      {
        path: "compras",
        loadChildren: () =>
          import("../modules/compras/compras.module").then(
            (m) => m.ComprasModule
          ),
      },
      {
        path: "tesoreria",
        loadChildren: () =>
          import("../modules/tesoreria/tesoreria.module").then(
            (m) => m.TesoreriaModule
          ),
      },
      {
        path: "logistica",
        loadChildren: () =>
          import("../modules/logistica/logistica.module").then(
            (m) => m.LogisticaModule
          ),
      },
      {
        path: "finanzas",
        loadChildren: () =>
          import("../modules/finanzas/finanzas.module").then(
            (m) => m.FinanzasModule
          ),
      },
      {
        path: "direccion",
        loadChildren: () =>
          import("../modules/direccion/direccion.module").then(
            (m) => m.DireccionModule
          ),
      },
      {
        path: "herramientas",
        loadChildren: () =>
          import("../modules/herramientas/herramientas.module").then(
            (m) => m.HerramientasModule
          ),
      },
      {
        path: "administracion",
        loadChildren: () =>
          import("../modules/administracion/administracion.module").then(
            (m) => m.AdministracionModule
          ),
      },
      {
        path: "recursos-humanos",
        loadChildren: () =>
          import("../modules/recursos-humanos/recursos-humanos.module").then(
            (m) => m.RecursosHumanosModule
          ),
      },
      {
        path: "capacitate",
        loadChildren: () =>
          import("../modules/capacitate/capacitate.module").then(
            (m) => m.CapacitateModule
          ),
      },
      {
        path: "tareas",
        loadChildren: () =>
          import(
            "../modules/asignador-de-tareas/asignador-de-tareas.module"
          ).then((m) => m.AsignadorDeTareasModule),
      },
      {
        path: "usuario",
        loadChildren: () =>
          import("../modules/usuario/usuario.module").then(
            (m) => m.UsuarioModule
          ),
      },
      {
        path: "contactcenter",
        loadChildren: () =>
          import("../modules/contact-center/contact-center.module").then(
            (m) => m.ContactCenterModule
          ),
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "/nuevo-dashboard",
      },
      {
        path: "**",
        redirectTo: "error/404",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
