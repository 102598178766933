<div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">Confirmacion de citas</h3>
      </div> 
    </div> 
    <div class="card-body">
        <div class="card-title"> 
                <p>
                    <code>La fecha agendada de las siguientes citas ha caducado. Favor de indicar asistencia de los clientes.</code>
                </p> 
            <table id="table" class="table table-head-custom table-vertical-center overflow-hidden">
                <thead> 
                  <th>
                    NOMBRE DEL CLIENTE
                    <!-- <app-sort-icon column="nombre" [activeColumn]="sorting.column" [reverse]="sorting.reverse" (sort)="sort($event)"></app-sort-icon> -->
                  </th>
                  <th>
                    SUCURSAL
                    <!-- <app-sort-icon column="pendientes" [activeColumn]="sorting.column" [reverse]="sorting.reverse" (sort)="sort($event)"></app-sort-icon> -->
                  </th>
                  <th>
                    ASESOR CC
                    <!-- <app-sort-icon column="no_asistio" [activeColumn]="sorting.column" [reverse]="sorting.reverse" (sort)="sort($event)"></app-sort-icon> -->
                  </th>
                  <th>
                    FECHA DE CITA
                    <!-- <app-sort-icon column="asistio" [activeColumn]="sorting.column" [reverse]="sorting.reverse" (sort)="sort($event)"></app-sort-icon> -->
                  </th>  
                  <th>
                    ACCIONES
                  </th>  
                    
                </thead>
                <tbody>
                    <tr *ngFor="let cita of list | orderBy: sorting.column : sorting.reverse | filter: busqueda | slice: (page-1) * pageSize : page * pageSize; index as i">
                        <td> {{cita.cliente_nombre}} </td>
                        <td> {{cita.sucursal_nombre}} </td>
                        <td> {{cita.contactcenter_nombre}} </td>
                        <td> {{cita.fecha | date}} {{cita.horario}} </td>
                        <td>
                            <a matTooltip="Asitencia" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1" (click)="accion(cita.id, 1)">
                                <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Check.svg'" cacheSVG="true" class="svg-icon svg-icon-md svg-icon-primary">
                                </span>
                            </a>
                            <a matTooltip="Falta" class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1" (click)="accion(cita.id, 0)">
                                <span [inlineSVG]="'./assets/media/svg/icons/Navigation/Close.svg'" cacheSVG="true" class="svg-icon svg-icon-md svg-icon-danger">
                                </span>
                            </a>
                        </td>
                    </tr> 
                </tbody>
            </table>
        </div>  
    </div>
  </div>  