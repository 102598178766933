<div class="tarea">
  <div class="modal-header">
    <div class="modal-title h4">Terminar actividad</div>
    <i style="cursor: pointer" (click)="modal.close()" class="fas fa-times"></i>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-md-6">
          <label
            >Tipo de solicitud: <b>{{ data.concepto }}</b></label
          >
        </div>
        <div class="col-md-6 text-md-right">
          <label
            >Fecha de solicitud:
            <b
              >{{ data.created_at | date: "hh:mm a" }} -
              {{ data.created_at | date: "dd/MM/yyyy" }}</b
            ></label
          >
        </div>
      </div>
    </div>

    <div class="mt-4">
      <label
        >Departamento: <b>{{ data.departamento }}</b></label
      >
    </div>

    <div class="mt-4">
      <label
        >Sucursal: <b>{{ data.sucursal }}</b></label
      >
    </div>

    <div class="mt-4">
      <label
        >Folio: <b>{{ data.folio }}</b></label
      >
    </div>
    <div class="mt-4">
      <label class="w-100">Nombre actividad:</label>
      <label
        ><b>{{ data.nombre_actividad_otro }}</b></label
      >
    </div>
    <div class="mt-4">
      <label class="w-100">Descripcion de solicitud:</label>
      <label
        ><b>{{ data.descripcion }}</b></label
      >
    </div>

    <hr />

    <div class="mt-4">
      <mat-label>Describe brevemente la resolucion de la actividad:</mat-label>
      <mat-form-field style="width: 100%" appearance="fill">
        <textarea [(ngModel)]="resolucion" rows="4" matInput></textarea>
      </mat-form-field>
    </div>

    <div *ngIf="data.supervisor_id" class="mt-5">
      <div class="d-flex align-items-center">
        <label class="m-0">
          <i class="fas fa-exclamation-circle text-primary mr-2"></i> Esta
          actividad ha sido marcada con requerimiento de supervision por
          <label class="m-0"
            ><b> {{ " " + data.supervisor + " " }} </b></label
          >
          para ser terminada</label
        >
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div>
      <button
        type="button"
        ngbAutofocus
        (click)="modal.dismiss()"
        class="btn btn-light btn-elevate mr-1"
      >
        Cancelar
      </button>
      <button
        (click)="finishActivity()"
        type="button"
        class="btn btn-success btn-elevate mr-1"
      >
        Terminar
      </button>
    </div>
  </div>

  <ngx-spinner
    bdColor="#ffffff"
    size="medium"
    color="#0078ff"
    name="finishActivity"
    type="ball-clip-rotate"
    [fullScreen]="false"
  ></ngx-spinner>
</div>
