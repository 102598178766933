<!--begin::Header-->
<ng-container *ngIf="extrasNotificationsDropdownStyle === 'light'">
    <div class="d-flex flex-column pt-12 bg-dark-o-5 rounded-top">
        <!--begin::Title-->
        <h4 class="d-flex flex-center">
            <span class="text-dark">Notificaciones</span>
            <span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{{nuevas}} nuevas</span
      >
    </h4>
    <!--end::Title-->

    <!--begin::Tabs-->
    <ul
      class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary mt-3 px-8"
      role="tablist"
    >
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_logs')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
          >Alertas</a
        >
      </li>

    </ul>
    <!--end::Tabs-->
  </div>
</ng-container>
<ng-container *ngIf="extrasNotificationsDropdownStyle === 'dark'">
  <div
    class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
    style="background-image: url('./assets/media/misc/bg-1.jpg');"
  >
    <!--begin::Title-->
    <h4 class="d-flex flex-center rounded-top">
      <span class="text-white">Notificaciones</span>
            <span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{{nuevas}} nuevas</span
      >
    </h4>
    <!--end::Title-->

    <!--begin::Tabs-->
    <ul
      class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
      role="tablist"
    >
      <!-- <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_notifications')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_notifications')"
          >Alerts</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_events')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_events')"
          >Events</a
        >
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setActiveTabId('topbar_notifications_logs')"
          [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
          >Alertas</a
        >
      </li>
      <li class="nav-item">

        <a
          class="nav-link cursor-pointer"
          data-toggle="tab"
          (click)="setLeidos()"
          >
          <i class="fas fa-check" style="margin-right: 6px;"></i>Marcar todas como leídas</a>
      </li>
    </ul>
    <!--end::Tabs-->
  </div>
</ng-container>
<!--end::Header-->

<!--begin::Content-->
<div class="tab-content">
  <!--begin::Tabpane-->
  <div
    class="tab-pane"
    id="topbar_notifications_events"
    role="tabpanel"
    [ngClass]="getActiveCSSClasses('topbar_notifications_logs')"
  >
    <!--begin::Nav-->
    <div class="topbar">
      <h4>Nuevas</h4>
      <p (click)="ver()">Ver todo</p>
    </div>
    <!-- <div class="todas">
      <p (click)="ver()"> Ver todas las notificaciones</p>
    </div> -->
    <div
      class="navi navi-hover scroll my-4"
      data-scroll="true"
      style="position: relative; max-height: 300px;"
      perfectScrollbar
    >
      <!--begin::Item-->
      <a (click)="selectNotification(n)" class="navi-item" *ngFor="let n of notificaciones">
        <div class="navi-link">
          <div class="navi-icon mr-2">
            <i class="flaticon2-paper-plane text-primary"></i>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold">
              {{n.asunto}}
            </div>
            <div class="text-muted">
              Hace {{ n.tiempo}}
            </div>
          </div>
        </div>
      </a>
        <!--end::Item-->


    </div>

    
    <!--end::Nav-->
    </div>
    <!--end::Tabpane-->

    <!--begin::Tabpane-->
    <div *ngIf="notificaciones.length == 0" class="tab-pane" id="topbar_notifications_logs" role="tabpanel" [ngClass]="getActiveCSSClasses('topbar_notifications_logs')">
        <!--begin::Nav-->
        <div class="d-flex flex-center text-center text-muted min-h-200px">
            No hay notificaciones.
        </div>
        <!--end::Nav-->
    </div>
    <!--end::Tabpane-->
    </div>
    <!--end::Content-->